
import { defineComponent, ref } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { SmsHistory } from '@/models/Interfaces';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from 'moment';

export default defineComponent({
    components: {
        DataTable,
        Column
    },
    setup() {
        const rows = ref<SmsHistory[]>([]);
        async function GetSmsHistory() {
            swal.showLoading();
            const apiPromise = api.GetSmsHistory();
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
            }
            if (apiResult.data) {
                rows.value = apiResult.data.smsHistory;
            }
            swal.close();
        }
        GetSmsHistory();

        function getDateFormat(data: string) {
            return moment(data)
                .utc()
                .format('DD/MM/YYYY hh:mm:ss');
        }
        return { rows, getDateFormat };
    }
});
